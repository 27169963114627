/* You can add global styles to this file, and also import other style files */

html {
  overflow: hidden;
  font-size: 62.5%; /* 1rem = 10px */
  color: var(--main-text-color);
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
